<script setup lang="ts">
import { AppButton, AppTypography } from '@/core/components';
const props = withDefaults(
  defineProps<{
    title: string;
    description: string;
    cancelText?: string;
    confirmText?: string;
    loading?: boolean;
    confirmOnly?: boolean;
  }>(),
  {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    loading: false,
    confirmOnly: false,
  }
);

const emits = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const modal = defineModel<boolean>();
</script>

<template>
  <v-dialog v-model="modal" width="500">
    <v-card width="100%" max-width="500">
      <v-toolbar density="compact">
        <v-toolbar-title class="text-center">
          <AppTypography type="title-2" element="h2">{{ title }}</AppTypography>
        </v-toolbar-title>
        <v-btn icon @click="emits('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center pt-2 pb-0">
        <slot name="description">
          <AppTypography element="span" type="body-1">
            {{ description }}
          </AppTypography>
        </slot>
      </v-card-text>
      <v-card-actions class="mt-8 pa-0">
        <v-row>
          <v-col cols="12">
            <AppButton
              data-cy="confirm-dialog-confirm-btn"
              rounded
              block
              :disabled="loading"
              color="primary"
              type="button"
              @click="emits('confirm')"
            >
              {{ confirmText }}
            </AppButton>
          </v-col>
          <v-col v-if="!!cancelText && !confirmOnly" cols="12">
            <v-btn
              class="confirm-dialog-cancel-btn"
              data-cy="confirm-dialog-cancel-btn"
              rounded
              block
              :disabled="loading"
              variant="plain"
              color="red"
              type="button"
              @click="emits('cancel')"
            >
              <AppTypography type="headline-1">{{ cancelText }}</AppTypography>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-card {
  padding: 16px;
}

.v-toolbar {
  background-color: inherit;
}
</style>
